import React from "react";
import banner from "../assets/images/rd500x500.png";
import ReactTypingEffect from "react-typing-effect";

const HomeBanner = () => {
  return (
    <section id="home" className="home-banner">
      <div className="container">
        <div className="row full-screen align-items-center">
          <div className="col-12 col-md-6  col-lg-7">
            <div className="home-text">
              <h4>Hello, My name is</h4>
              <h5>Ronny Delgado</h5>
              <p>
                A&nbsp;
                <span className="theme-bg">
                  <ReactTypingEffect
                    text={["Software Engineer", "Full Stack Developer"]}
                    speed={50}
                    eraseDelay={3000}
                    typingDelay={500}
                  />
                </span>
              </p>
              <div className="btn-bar"></div>
            </div>
          </div>
          <div className="col-md-6 col-lg-5">
            <img src={banner} title="ronnydelgado" alt="ronnydelgado" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;

import React from "react";
import "../src/styles/styles.css";
import Header from "./components/Header";
import MainContainer from "./components/MainContainer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BlogList from "./components/BlogList";
import BlogPost from "./components/BlogPost";

const App = () => {
  return (
    <Router>
      <div className="wrapper">
        <Header />
        <Routes>
          <Route path="/" element={<MainContainer />} />
          <Route path="/my-blog" element={<BlogList />} />
          <Route path="/my-blog/:slug" element={<BlogPost />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;

import React, { useRef } from "react";
import useOnScreen from "../customHooks/useOnScreen";

const sendEmail = () => {
  window.open("mailto:ronny@ronnydelgado.com");
};

const Contact = () => {
  const contactTitleRef = useRef();
  const isVisible = useOnScreen(contactTitleRef);

  return (
    <section id="contact" className="section section-small-padding contact-bg">
      <div className="container">
        <div className="row">
          <div className="about-box">
            <div className="about-me">
              <h2
                ref={contactTitleRef}
                className={
                  isVisible ? "animate__animated animate__heartBeat" : ""
                }
              >
                Contact<span className="theme-color">&nbsp;Me</span>
              </h2>
              <div className="home-banner">
                <p>Let's chat!</p>
              </div>
            </div>
            <div className="btn-bar">
              <button onClick={sendEmail} className="m-btn m-btn-theme">
                Send Email
              </button>
            </div>
            <div className="margin-bottom-40"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;

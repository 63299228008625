import React from "react";
import blogPostsData from "../contentlayer/generated/BlogPost/_index.json";
import { useParams } from "react-router-dom";
import { formattedDate } from "../helpers/dates";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypePrism from "rehype-prism-plus";
import remarkGfm from "remark-gfm";
import "prismjs/themes/prism-okaidia.css";

const BlogPost = () => {
  const { slug } = useParams();
  const post = blogPostsData.find((p) => p.slug === slug);

  if (!post) return <h1>Post not found</h1>;

  return (
    <section>
      <div className="container">
        <div className="blog-post-container">
          <h1>{post.title}</h1>
          <p style={{ color: "gray", fontStyle: "italic" }}>
            {formattedDate(post.date)}
          </p>

          <ReactMarkdown
            children={post.body.raw}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw, rehypePrism]}
            className="markdown-content"
          />

          <hr />
          <div className="tags-container">
            {post.tags.map((tag, index) => (
              <span key={index} className="tag-pill">
                {tag}
              </span>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogPost;

import React, { useState } from "react";
import logo from "../assets/images/rd-165x165.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import {
  faHome,
  faCogs,
  faUserTie,
  faAt,
  faThumbsUp,
  faBlog,
} from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const [isMenuOpen, setMenuIsOpen] = useState(true);

  const toggleMenu = () => {
    setMenuIsOpen(!isMenuOpen);
  };

  return (
    <header className="header-style">
      <nav className="header-nav">
        <div className="logo">
          <a className="navbar-brand" href="/#home">
            <img src={logo} title="" alt="" />
          </a>
          <button
            onClick={toggleMenu}
            className={isMenuOpen ? "toggler-menu" : "toggler-menu open"}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>

        <ul className="social-icons">
          <li>
            <a
              href="https://www.linkedin.com/in/ronnydelgado/"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
          </li>
          <li>
            <a
              href="https://github.com/ronnythedev"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/iamrondelgado"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/ronny_delgado_"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/ronnydelgado_/"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </li>
        </ul>

        <div
          className={
            isMenuOpen ? "top-nav nav_menu" : "top-nav nav_menu menu-open"
          }
          id="navbarKeira"
        >
          <ul className="k-navbar-nav">
            <li>
              <a className="nav-link active" href="/#home">
                <FontAwesomeIcon icon={faHome} />
                <span>Home</span>
              </a>
            </li>
            <li>
              <a className="nav-link" href="/#values">
                <FontAwesomeIcon icon={faCogs} />
                <span>My Values</span>
              </a>
            </li>
            <li>
              <a className="nav-link" href="/#recommendations">
                <FontAwesomeIcon icon={faThumbsUp} />
                <span>Recommendations</span>
              </a>
            </li>
            <li>
              <a className="nav-link" href="/#about">
                <FontAwesomeIcon icon={faUserTie} />
                <span>Tech Stack</span>
              </a>
            </li>
            <li>
              <a className="nav-link" href="/my-blog">
                <FontAwesomeIcon icon={faBlog} />
                <span>My Blog</span>
              </a>
            </li>
            <li>
              <a className="nav-link" href="/#contact">
                <FontAwesomeIcon icon={faAt} />
                <span>Contact Me</span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;

import React from "react";
import blogPostsData from "../contentlayer/generated/BlogPost/_index.json";
import { Link } from "react-router-dom";
import { formattedDate } from "../helpers/dates";

const BlogList = () => {
  return (
    <section>
      <div className="container">
        <div className="about-box">
          <div className="about-me"></div>
          <h1 className="section-title-01">My Blog</h1>
          <ul className="blog-list">
            {blogPostsData.map((post) => (
              <li key={post.slug}>
                <Link to={`/my-blog/${post.slug}`}>{post.title}</Link>
                <small>{formattedDate(post.date)}</small>

                <div className="tags-container">
                  {post.tags.map((tag, index) => (
                    <span key={index} className="tag-pill">
                      {tag}
                    </span>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default BlogList;

import React from "react";

const BlogSection = () => {
  return (
    <section id="goToBlog" className="section section-small-padding gray-bg">
      <div className="container">
        <div className="row">
          <div className="about-box">
            <div className="about-me">
              <h2>
                Check<span className="theme-color">&nbsp;my Blog</span>
              </h2>
              <a href="/my-blog" className="m-btn m-btn-theme">
                Go to My Blog
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogSection;

import React from "react";

const GrabResume = () => {
  return (
    <section id="grabResume" className="section section-small-padding">
      <div className="container">
        <div className="row">
          <div className="about-box">
            <div className="about-me">
              <h2>
                My<span className="theme-color">&nbsp;Resume?</span>
              </h2>
              <a
                href="/Ronny_Delgado_Resume.pdf"
                className="m-btn m-btn-theme"
                download
              >
                Download it here
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GrabResume;
